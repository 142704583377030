<template>
  <div class="box">
    <div class="home" ref="bigbox">
      <div class="wzs-banner"></div>
      <div class="info">
        <ul class="info-list">
          <li
            :class="['info-box', item.on ? 'on' : '']"
            v-for="(item, index) in list"
            :key="index"
            @mouseenter="mouseenter(item,index)"
            @mouseleave="mouseleave(item,index)"
            :id="'enter' + index"
            :style="{backgroundImage: 'url('+item.img+')'}"
          >
            <div class="text">
              <h5>{{ item.title }}</h5>
              <p><span>￥</span>{{ item.Price }}<span>/年</span></p>
            </div>
            <div class="gm" @click="gm">
              <a href="#">立即注册购买</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="hezuo">商务合作或者想要更多优惠请添加右方联系方式！</div>
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs } from "vue";
export default defineComponent({
  name: "Lottie",
  setup() {
    const state = reactive({
      list: [
        {
          Price: 200,
          title: "1个工位",
          on: false,
          img:require("../assets/dg/iconbg01.png")
        },
        {
          Price: 1998,
          title: "10个工位",
          on: false,
          img:require("../assets/dg/iconbg02.png")
        },
        {
          Price: 3598,
          title: "20个工位",
          on: false,
          img:require("../assets/dg/iconbg03.png")
        },
        {
          Price: 4798,
          title: "30个工位",
          on: false,
          img:require("../assets/dg/iconbg04.png")
        },
        {
          Price: 5998,
          title: "50个工位",
          on: false,
          img:require("../assets/dg/iconbg05.png")
        },
      ],
    });
    const mouseenter = (item,index) => {
      console.log(item,index);
      item.on = true;
      item.img = require("../assets/dg/iconbg0"+(index+6)+".png")
    };
    const mouseleave = (item,index) => {
        console.log(index);
      item.on = false;
       item.img = require("../assets/dg/iconbg0"+(index+1)+".png")
    };
    const gm = ()=>{
        console.log("跳转登陆注册购买页面");
    }

    return {
      ...toRefs(state),
      mouseenter,
      mouseleave,
      gm
    };
  },
});
</script>

<style scoped lang="scss">
.box {
  height: 1500px;
  .home {
    position: relative;
    text-align: center;
    .wzs-banner {
      width: 100%;
      min-width: 1320px;
      height: 1031px;
      background-image: url("../assets/dg/banner.png");
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: cover;
    }
    .info {
      position: absolute;
      left: 0;
      bottom: -103px;
      z-index: 9;
      width: 100%;
      min-width: 1320px;
      padding: 0 10px;
      box-sizing: border-box;
      .info-list {
        width: 1300px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        list-style: none;

        .info-box {
          width: 228px;
          height: 312px;
          background-repeat: no-repeat;
          background-position: 50%;
          background-size: cover;
          padding: 50px 48px;
          box-sizing: border-box;
          text-align: left;
          border-radius: 33px;
          transition: all 0.3s;
          .text {
            h5 {
              font-size: 18px;
              font-weight: 400;
              line-height: 18px;
              color: #3e3e3e;
            }
            p {
              font-size: 40px;
              font-weight: bold;
              line-height: 40px;
              color: #b27a25;
              span {
                font-size: 16px;
              }
              margin-top: 20px;
            }
          }
          .gm {
            a {
              font-size: 20px;
              font-family: PingFang SC;
              font-weight: 400;
              line-height: 18px;
              color: #fff;
              position: absolute;
              bottom: 55px;
              text-decoration: none;
            }
          }
        }
        .on {
          box-shadow: 0 0 24px 0 rgb(47 102 173 / 50%);
          transform :translate3d(0,-10px,0);
        }
      }
    }
  }
  .hezuo {
    width: 100%;
    min-width: 1320px;
    text-align: center;
    padding-top: 150px;
    height: 300px;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: 400;
    color: #3e3e3e;
  }
}
</style>